<template>
  <el-row v-loading="isLoading">
    <div style="position: fixed;" :style="cardMarginTop">
      <div class="grid-content bg-purple">
        <div class="pic member_card" :style="{ background: dialogImageUrl2 ? 'url('+ dialogImageUrl2 +') no-repeat' : bgColorGradient, backgroundSize: '100% 100%' }">
          <div class="logo_area">
            <span class="vip_logo">
                <img id="js_logo_url_preview" :src="dialogImageUrl">
            </span>
            <span id="js_brand_name_preview" class="titles">
                {{ruleForm.cou_title}}
            </span><br/>
            <p id="js_title_preview" class="card_title">
              {{ruleForm.gift_name}}
            </p><br/>
             <p id="js_title_preview" class="card_lit_title">
              {{ruleForm.cou_subhead}}
            </p>
            <div class="use_btn"><el-button type="success">立即使用</el-button></div>
            <span class="use_time">有效时间：2022.11.17-2022.12.16</span><br/>
            <div class="others">
              <div class="card_infos" v-if="ruleForm.cou_writer_off == 'CODE_TYPE_ONLY_BARCODE'">
                <!-- <span>核销展示码(点击立即使用时候显示)</span> -->
                <!-- <span><i class="el-icon-arrow-right"></i></span> -->
                 <el-image
                  style="width: 250px; height: 60px"
                  :src="ruleForm.barcode_url"
                  fit="cover"
                ></el-image>
                <span style="margin-top: 20px; margin-bottom: 20px"
                  >888 8888 8888 8888</span
                >
              </div>
              <div class="card_infos" v-else-if="ruleForm.cou_writer_off == 'CODE_TYPE_ONLY_QRCODE'">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="ruleForm.qrcode_url"
                  fit="cover"
                ></el-image>
                <span style="margin-top: 10px; margin-bottom: 10px">888 8888 8888 8888</span>
              </div>
              <!-- <div class="card_infos">
                <span>优惠券详情</span>
                <span><i class="el-icon-arrow-right"></i></span>
              </div>
              <div class="card_infos">
                <span>公众号</span>
                <span><i class="el-icon-arrow-right"></i></span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-left: 420px;">
      <div class="grid-content bg-purple-light vip-form" style="padding-top: 10px;">
        <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
        >
          <div>
            <h3>基础设置</h3>
            <el-divider></el-divider>
            <!-- <el-form-item label="商户：" prop="meName">
              {{ruleForm.meName}}
            </el-form-item> -->
            <el-form-item label="商家：" prop="shanghu">
              <el-select v-model="ruleForm.meId" :disabled="!isAdd || shDisabled" placeholder="请选择商户">
                <el-option
                        v-for="item in merchants"
                        :key="item.meId"
                        :label="item.meName"
                        :value="item.meId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="品牌：" prop="cou_title">
              <el-input v-model="ruleForm.cou_title" placeholder="请输入品牌方名称" maxlength="12" show-word-limit class="createTimeInputWidth"></el-input>
            </el-form-item>
             <el-form-item label="标题：" prop="gift_name">
              <el-input v-model="ruleForm.gift_name" placeholder="请输入代金券标题" maxlength="12" show-word-limit class="createTimeInputWidth"></el-input>
            </el-form-item>
            <el-form-item label="副标题：" prop="cou_subhead">
              <el-input v-model="ruleForm.cou_subhead" placeholder="卡副标题文案，例如：使用时候请出示" maxlength="9" show-word-limit class="createTimeInputWidth"></el-input>
            </el-form-item>
            <el-form-item label="券logo：" prop="cou_logo">
              <!-- auto-upload为是否自动加载；action为图片要上传到的地址，这里随便填一个，因为暂时不用 -->
              <!-- class为动态样式选择，是实现上传图片后上传框消失的关键 -->
              <el-upload action="/api/common/upload"
                         list-type="picture-card"
                         :on-preview="handlePictureCardPreview"
                         :on-remove="handleRemove"
                         :auto-upload="false"
                         :on-change="handleChange"
                         :class="objClass"
                         :file-list="fileList" :limit="1">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color: #999;font-size: 12px;">
                尺寸120px*120px，支持jpg、jpeg、png图片，文件小于1M
              </span>
              <!-- el-dialog为点击预览图的放大按钮后弹出来的框，在框内显示放大的图片 -->
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
            <el-form-item label="代金券颜色：" prop="cou_color"><!--TODO 只支持一些特定的颜色，所以这个不是随便选-->
              <!--<el-color-picker
                      v-model="ruleForm.tim_color"
                      :show-alpha="false"
                      @change="bgColorChange"
                      :predefine="predefineColors">
              </el-color-picker>-->
              <span class="select-color-radio">
                <el-radio-group v-model="ruleForm.cou_color" @change="bgColorChange">
                  <el-radio class="color010" label="Color010"><span></span></el-radio>
                  <el-radio class="color020" label="Color020"><span></span></el-radio>
                  <el-radio class="color030" label="Color030"><span></span></el-radio>
                  <el-radio class="color040" label="Color040"><span></span></el-radio>
                  <el-radio class="color050" label="Color050"><span></span></el-radio>
                  <el-radio class="color060" label="Color060"><span></span></el-radio>
                  <el-radio class="color070" label="Color070"><span></span></el-radio>
                  <el-radio class="color080" label="Color080"><span></span></el-radio>
                  <el-radio class="color081" label="Color081"><span></span></el-radio>
                  <el-radio class="color082" label="Color082"><span></span></el-radio>
                  <el-radio class="color090" label="Color090"><span></span></el-radio>
                  <el-radio class="color100" label="Color100"><span></span></el-radio>
                  <el-radio class="color101" label="Color101"><span></span></el-radio>
                  <el-radio class="color102" label="Color102"><span></span></el-radio>
                </el-radio-group>
              </span>
            </el-form-item>
            <el-form-item label="核销展示：" prop="cou_writer_off">
              <el-radio-group v-model="ruleForm.cou_writer_off">
                <el-radio label="CODE_TYPE_ONLY_BARCODE">卡号和条形码</el-radio>
                <el-radio label="CODE_TYPE_ONLY_QRCODE">卡号和二维码</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="状态：" prop="gift_use_type">
              <el-radio-group v-model="ruleForm.gift_use_type">
                <el-radio label="0">上架</el-radio>
                <el-radio label="1">下架</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div>
            <h3>代金券规则</h3>
            <el-divider></el-divider>
            <el-form-item label="设置库存：" prop="cou_count"><!--对应init_bonus-->
              <el-input-number v-model="ruleForm.cou_count" :min="1" :max="10000000" label="设置库存次卡张数"></el-input-number>
            </el-form-item>
            <el-form-item label="券面额：" prop="gift_amount"><!--对应init_bonus-->
              <el-input-number v-model="ruleForm.gift_amount" :min="1" :max="100000" label="设置券面额"></el-input-number>
            </el-form-item>
            <!-- <el-form-item label="支持核券：" prop="tim_pay_type">
              <el-checkbox-group v-model="ruleForm.tim_pay_type">
                <el-checkbox label="1">微信支付</el-checkbox>
                <el-checkbox label="2">支付宝支付</el-checkbox>
                <el-checkbox label="3">会员支付</el-checkbox>
                <el-checkbox label="4">银行卡</el-checkbox>
                <el-checkbox label="5">现金</el-checkbox>
                <el-checkbox label="6">组合支付</el-checkbox>
              </el-checkbox-group>
            </el-form-item> -->
             <!-- <el-form-item label="核销场景：" prop="tim_pay_type">
              <el-checkbox-group v-model="ruleForm.tim_pay_type">
                <el-checkbox label="1">门店收银</el-checkbox>
                <el-checkbox label="2">线上商城</el-checkbox>
              </el-checkbox-group>
            </el-form-item> -->
            <el-row style="margin-bottom: 0;">
              <el-col :span="6">
                <el-form-item label="有效日期：" prop="cou_valid_date_type">
                  <el-select v-model="ruleForm.cou_valid_date_type" placeholder="请选择" style="width:180px">
                      <el-option label="相对时间" value="0"></el-option>
                      <el-option label="指定时间" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="ruleForm.cou_valid_date_type === '0'">
                <el-form-item prop="cou_valid_date_day">
                  <el-input v-model="ruleForm.cou_valid_date_day"  onkeyup="value=value.replace(/[^\d]/g,'')">
                    <template slot="prepend">领取后</template>
                    <template slot="append">天内有效</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-else-if="ruleForm.cou_valid_date_type === '1'">
                <el-form-item prop="cou_valid_date_range">
                  <el-date-picker
                          v-model="ruleForm.cou_valid_date_range"
                          type="daterange"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 0;">
              <el-col :span="6">
                <el-form-item label="最低消费：" prop="gift_type">
                  <el-select v-model="ruleForm.gift_type" placeholder="请选择" style="width:180px">
                      <el-option label="不限制" :value="0"></el-option>
                      <el-option label="支付金额" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="9" v-if="ruleForm.gift_type === 1">
                <el-form-item prop="gift_consum">
                  <el-input v-model="ruleForm.gift_consum" style="margin-left:10px;width:260px" onkeyup="value=value.replace(/[^\d]/g,'')">
                    <template slot="prepend">消费满</template>
                    <template slot="append">元使用券</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!--TODO 适用门店选择 是否需要-->
            <!--TODO 自定义入口一 自定义入口二 是否需要-->
          </div>
          <!--TODO 分享文案是否必须-->



          <div style="margin-top: 50px;">
            <h3>代金券详情</h3>
            <el-divider></el-divider>
            <el-form-item label="特权说明：" prop="cou_desc"><!--对应prerogative-->
              <el-input placeholder="请输入代金券特权说明" type="textarea" maxlength="1024"
                        show-word-limit :autosize="{ minRows: 5, maxRows: 10 }" v-model="ruleForm.cou_desc" class="createTimeInputWidth" />
            </el-form-item>
            <el-form-item label="操作提示：" prop="cou_reminder"><!--对应prerogative-->
              <el-input placeholder="请输入操作提示" maxlength="100"
                        show-word-limit v-model="ruleForm.cou_reminder" class="createTimeInputWidth" />
            </el-form-item>
            <el-form-item label="使用须知：" prop="cou_know">
              <el-input placeholder="请输入使用须知" v-model="ruleForm.cou_know" class="createTimeInputWidth"
                        maxlength="100"
                        show-word-limit />
              <!--<el-input placeholder="请输入使用须知" v-model="ruleForm.cou_know" :disabled="true" />-->
            </el-form-item>
          </div>
          <div style="margin-top: 50px;">
            <h3>商户介绍</h3>
            <el-divider></el-divider>
            <el-form-item label="客服电话：" prop="gift_phone"><!--对应 service_phone-->
             <el-input placeholder="客服电话" maxlength="20" v-model="ruleForm.gift_phone" class="createTimeInputWidth" />
            </el-form-item>
          </div>
          <el-form-item label="适用门店："><!--未保存至数据库-->
            <el-radio-group v-model="typeStore">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">选择门店</el-radio>
            </el-radio-group>
            <el-form-item v-if="typeStore === 1" style="padding-left: 16%" label="">
              <el-button
                type="text"
                @click="choiceStore"
                size="medium"
                >已选择{{this.checkList.length}}门店</el-button>
            </el-form-item>
          </el-form-item>
         
          <el-form-item>
            <el-button type="primary" @click="onSubmit('ruleForm')">保存</el-button>
            <el-button @click="() => { this.$router.go(-1) }">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--适用门店对话框-->
    <el-dialog
      title="使用门店"
      :visible.sync="dialogUseStore"
      width="700px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div style="height: 15px;padding-left: 10px">
        共<span style="color: red">{{this.quantity}}</span>家门店 &nbsp;&nbsp;&nbsp;&nbsp;
        已选择&nbsp;<span style="color: red">{{checkedAll ? total : checkList.length}}</span>&nbsp;家门店
      </div>
      <el-table
        ref="multipleTables"
        :data="addData"
        tooltip-effect="dark"
        border
        style="width: 100%"
        stripe
        size="small"
        :row-style="{ height: '40px' }"
        :cell-style="{ padding: '1px 0' }"
        @select="selectItem"
        @select-all="selectAll"
      >
      <el-table-column type="selection" width="75"  align="center"></el-table-column>
        <el-table-column
          prop="soeName"
          label="门店名称"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="soeAddr"
          label="门店地址"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="storeSubmit"
          >确认</el-button
        >
      </div>
    </el-dialog>
    </div>
  </el-row>
</template>

<script>
  import api from "../../api/giftCard";
  import config from "../../config/index";
  import merc from "../../api/merchant";
export default {
  name: "createTimeCard",
  data() {
    return {
      shDisabled: true,
      couId:this.$route.query.couId,
      couBatch:this.$route.query.couBatch,
      cardMarginTop: {},
      isAdd: true,
      isLoading: false,
      dialogVisible: false,
      fileList: [],
      objClass: {
        upLoadShow: true,
        upLoadHide: false,
      },
      dialogImageUrl: '', //图片回显
      dialogVisible2: false,
      fileList2: [],
      objClass2: {
        upLoadShow: true,
        upLoadHide: false,
      },
      dialogImageUrl2: '',
      merchants:[],
      ruleForm: {
        couId: "",
        timId: "",
        // meName: '小睿智洗',
        cou_title: "",
        gift_name:"",
        cou_subhead: "",
        cou_logo: null,
        cou_color: 'Color010',
        cou_writer_off: "CODE_TYPE_ONLY_BARCODE",
        gift_use_type: '',
        gift_type:0,
        cou_desc: "",
        cou_reminder: "使用时主动出示次卡，方便核销",
        symd: 'Y',
        cou_count: '',
        gift_consum: '',
        gift_amount:'',
        cou_valid_date_type:"",
        cou_valid_date_day: '30',
        cou_valid_date_range: [],
        time_specification: [
          {original_cost: '', selling_price: '', timer: ''}
        ],
        cou_know: '先领取再使用',
        gift_phone: '',
        barcode_url: require("@/assets/code_type2.jpg"),
        qrcode_url: require("@/assets/code_type1.jpg"),
        meId: '',
      },
      rules: {
        cou_title: [
          { required: true, message: "请输入次卡品牌方", trigger: "blur" },
        ],
        gift_name:[
          { required: true, message: "请输入次卡名称", trigger: "blur" },
        ],
        cou_subhead: [
          { required: true, message: "请输入次卡副标题", trigger: "blur" },
        ],
        cou_logo: [
          { required: true, message: "请选择品牌logo图标", trigger: "change" },
        ],
        tim_type: [
          { required: true, message: "请选择展示类型", trigger: "change" },
        ],
        cou_count: [
          { required: true, message: "请输入库存次卡张数", trigger: "change" },
        ],
        cou_valid_date_type: [
          { required: true, message: "请选择有效日期", trigger: "change" },
        ],
        cou_valid_date_day: [
          { required: true, message: "请输入有效期限", trigger: "change" },
        ],
        cou_valid_date_range: [
          { required: true, message: "请选择有效日期区间", trigger: "change" },
        ],
        cou_desc: [
          { required: true, message: "请输入特权说明", trigger: "change" },
        ],
        cou_reminder: [
          { required: true, message: "请输入操作提示", trigger: "change" },
        ],
        cou_know: [
          { required: true, message: "请输入使用须知", trigger: "change" },
        ],
        gift_amount:[{
          required: true, message: "请输入面额", trigger: "change"
        }],
        gift_use_type:[{
          required: true, message: "请选择状态", trigger: "change"
        }],
        gift_phone: [
          { required: false, message: "请输入客服电话", trigger: "blur" },
          {
            pattern: /^(\(\d{3,4}-)|(\d{3.4}-)?\d{3,8}$/,
            message: '请输入正确的电话号码',
          }
        ],
      },
      predefineColors: [
        '#63b359',
        '#2c9f67',
        '#509fc9',
        '#5885cf',
        '#9062c0',
        '#d09a45',
        '#e4b138',
        '#ee903c',
        '#a9d92d',
        '#dd6549',
        '#cc463d',
      ],
      bgColorGradient: '#63b359',
      quantity:0,//选择门店数量
      dialogUseStore:false,//适用门店填出框
      addData:[],
      checkList:[],
      checkedAll: false,
      total:'',
      typeStore:0,
      soeId:'',
    };
  },
  created() {
    this.useStore();
    this.getMerchants();
    api.getUstoreStore(this.couBatch).then(res =>{
      this.checkList = res.data.data.result
    })
    if(this.$route.query.couId){
      this.isAdd = false;
      this.edit();
    }
  },
  watch: {
    $route(to, from){//监听地址栏变动，否则 点了修改，又点了菜单会出问题
      if(to.path === '/giftCard/createGiftCard'){//从修改变为了新增
        this.isAdd = true;
        this.$refs["ruleForm" ].resetFields();
      }
    },
  },
  mounted(){
    // addEventListener 方法用于向指定元素添加事件
    // 参数1：必选 事件名 例如： click , scroll , mouseenter
    // 参数2：必选 指定事件触发时的执行函数
    // 参数3：可选 布尔值 指定事件是否咋在捕获或冒泡阶段执行
    // true - 事件在句柄捕获阶段执行
    // false - 事件句柄冒泡阶段执行
    window.addEventListener('scroll', this.scrollDs , true)
  },
  methods: {
    getMerchants(){
      merc.getmerchantsByAuth().then(res=>{
        this.merchants = res.data.data.merchants;
        if(res.data.data.merchants && res.data.data.merchants.length === 1) {
          this.ruleForm.meId = res.data.data.merchants[0].meId;
          this.shDisabled = true;
        } else if(res.data.data.merchants && res.data.data.merchants.length > 1) {
          this.shDisabled = false;
        }

      })
    },
    edit(){
      this.isLoading = true;
      api.echoByCouId(this.couId).then(res =>{
        let ruleInfo = res.data.data.result[0];
        this.ruleForm.cou_id = ruleInfo.cou_id;
        this.ruleForm.cou_batch = ruleInfo.cou_batch;

        this.ruleForm.cou_count = ruleInfo.cou_count;
        this.ruleForm.cou_desc = ruleInfo.cou_desc;
        this.ruleForm.cou_know = ruleInfo.cou_know;
        if (ruleInfo.cou_logo) {
          let logoUrl = config.baseUrl + "/" + ruleInfo.cou_logo;
          this.fileList = [{ url: logoUrl }];
          this.objClass.upLoadHide = true; //上传图片后置upLoadHide为真，隐藏上传框
          this.objClass.upLoadShow = false;
          this.dialogImageUrl = logoUrl;
          this.ruleForm.cou_logo = "noEdit";
        }
        if(ruleInfo.cou_batch){
          this.ruleForm.cou_color = ruleInfo.cou_color;
          this.bgColorChange(ruleInfo.cou_color);
        }
        this.ruleForm.cou_reminder = ruleInfo.cou_reminder;
        this.ruleForm.cou_subhead = ruleInfo.cou_subhead;
        this.ruleForm.cou_title = ruleInfo.cou_title;
        this.ruleForm.cou_type = ruleInfo.cou_type;
        this.ruleForm.cou_valid_date_day = ruleInfo.cou_valid_date_day;
        this.ruleForm.cou_valid_date_type = ruleInfo.cou_valid_date_type;
        this.ruleForm.cou_writer_off = ruleInfo.cou_writer_off;
        this.ruleForm.gift_amount = ruleInfo.gift_amount;
        this.ruleForm.gift_id = ruleInfo.gift_id;
        this.ruleForm.gift_name = ruleInfo.gift_name;
        this.ruleForm.gift_phone = ruleInfo.gift_phone;
        this.ruleForm.gift_type = ruleInfo.gift_type;
        this.ruleForm.gift_use_type = '' + ruleInfo.gift_use_type;
        this.ruleForm.me_name = ruleInfo.me_name;
        this.ruleForm.gift_consum = ruleInfo.gift_consum;
        this.ruleForm.cou_valid_date_range = ruleInfo.cou_valid_date_range ? ruleInfo.cou_valid_date_range.split(',') : [];
        this.ruleForm.meId = ruleInfo.cou_me_id;
        this.typeStore = ruleInfo.cou_soe_type
        this.isLoading = false;
      })
    },
    getFileNameByPath(filePath){//根据文件全路径截取文件名
      const spliceLength = filePath.lastIndexOf("/");
      let fileName = filePath.slice(spliceLength + 1);
      return fileName;
    },
    handleChange(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension1 = testmsg === 'jpg'
      const extension2 = testmsg === 'jpeg'
      const extension3 = testmsg === 'png'
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!extension1 && !extension2 && !extension3) {
        this.$message({
          message: '上传的图片只能是.jpg,.jpeg,.png格式!',
          type: 'warning'
        })
        this.fileList = [];
      } else if (!isLt1M) {
        this.$message({
          message: '上传的图片大小不能超过1M!',
          type: 'warning'
        })
        this.fileList = [];
      } else {
        this.objClass.upLoadHide = true;//上传图片后置upLoadHide为真，隐藏上传框
        this.objClass.upLoadShow = false;
        this.dialogImageUrl = file.url;
        this.ruleForm.cou_logo = file.raw;
      }
    },
    handleRemove(file, fileList) {
      this.objClass.upLoadShow = true;//删除图片后显示上传框
      this.objClass.upLoadHide = false;
      this.dialogImageUrl = '';
      this.ruleForm.cou_logo = null;
    },
    // 点击预览图的放大按钮后会触发handlePictureCardPreview
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange2(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      // .jpg,.jpeg,.png,.pdf,.PDF
      const extension1 = testmsg === 'jpg'
      const extension2 = testmsg === 'jpeg'
      const extension3 = testmsg === 'png'
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!extension1 && !extension2 && !extension3) {
        this.$message({
          message: '上传的图片只能是.jpg,.jpeg,.png格式!',
          type: 'warning'
        })
        this.fileList2 = [];
      } else if (!isLt2M) {
        this.$message({
          message: '上传的图片大小不能超过2M!',
          type: 'warning'
        })
        this.fileList2 = [];
      } else {
        this.objClass2.upLoadHide = true;//上传图片后置upLoadHide为真，隐藏上传框
        this.objClass2.upLoadShow = false;
        this.dialogImageUrl2 = file.url;
      }
    },
    handleRemove2(file, fileList) {
      this.objClass2.upLoadShow = true;//删除图片后显示上传框
      this.objClass2.upLoadHide = false;
      this.dialogImageUrl2 = '';
    },
    // 点击预览图的放大按钮后会触发handlePictureCardPreview
    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    },
    bgColorChange(val){
      let color = "";
      switch(val) {
        case 'Color010':
          color = '#63b359';
          break;
        case 'Color020':
          color = '#2c9f67';
          break;
        case 'Color030':
          color = '#509fc9';
          break;
        case 'Color040':
          color = '#5885cf';
          break;
        case 'Color050':
          color = '#9062c0';
          break;
        case 'Color060':
          color = '#d09a45';
          break;
        case 'Color070':
          color = '#e4b138';
          break;
        case 'Color080':
          color = '#ee903c';
          break;
        case 'Color081':
          color = '#f08500';
          break;
        case 'Color082':
          color = '#a9d92d';
          break;
        case 'Color090':
          color = '#dd6549';
          break;
        case 'Color100':
          color = '#cc463d';
          break;
        case 'Color101':
          color = '#cf3e36';
          break;
        case 'Color102':
          color = '#5E6671';
          break;
      }
      this.bgColorGradient = '-webkit-linear-gradient(' + this.lightenDarkenColor(color, 40) + ' 0, ' + color + ' 100%';
    },
    lightenDarkenColor(col, amt) {//获取比col颜色更浅或更深的颜色（通过正负值控制）
      var usePound = false;
      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }
      var num = parseInt(col,16);
      var r = (num >> 16) + amt;
      if (r > 255) r = 255;
      else if  (r < 0) r = 0;
      var b = ((num >> 8) & 0x00FF) + amt;
      if (b > 255) b = 255;
      else if  (b < 0) b = 0;
      var g = (num & 0x0000FF) + amt;
      if (g > 255) g = 255;
      else if (g < 0) g = 0;
      return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true;
          let formData = new FormData();
          formData.append("cou_id", this.ruleForm.cou_id);
          formData.append("cou_title", this.ruleForm.cou_title);
          formData.append("cou_subhead", this.ruleForm.cou_subhead);
          formData.append("cou_logo", this.ruleForm.cou_logo);
          formData.append("cou_color", this.ruleForm.cou_color);
          formData.append("gift_type", this.ruleForm.gift_type);//gift_consum
          formData.append("gift_name", this.ruleForm.gift_name);
          formData.append("gift_consum", this.ruleForm.gift_consum);
          formData.append("cou_desc", this.ruleForm.cou_desc);
          formData.append("cou_reminder", this.ruleForm.cou_reminder);
          formData.append("cou_writer_off", this.ruleForm.cou_writer_off);
          formData.append("symd", this.ruleForm.symd);
          formData.append("gift_amount", this.ruleForm.gift_amount);
          formData.append("cou_count", this.ruleForm.cou_count);
          formData.append("cou_valid_date_type", this.ruleForm.cou_valid_date_type);
          formData.append("cou_valid_date_day", this.ruleForm.cou_valid_date_day);
          formData.append("cou_valid_date_range", this.ruleForm.cou_valid_date_range);
          // formData.append("time_specification", JSON.stringify(this.ruleForm.time_specification));
          formData.append("cou_know", this.ruleForm.cou_know);
          formData.append("gift_phone", this.ruleForm.gift_phone);
          formData.append("gift_id",this.ruleForm.gift_id);
          formData.append("meId",this.ruleForm.meId);
          formData.append("gift_use_type",this.ruleForm.gift_use_type);
          api.changeGiftInfo(this.isAdd ? "add" : "edit", formData,this.typeStore,this.soeId).then(res => {
            this.isLoading = false;
            if (res.data.data.result === "success") {
              this.$notify({
                title: "操作成功",
                message: "提示信息",
                position: "bottom-right",
                type: "success",
              });
              this.$router.go(-1)
            }else if(res.data.data.result === "error"){
              this.$notify({
                title: "请选择适用门店",
                message: "提示信息",
                position: "bottom-right",
                type: "error",
              });
            }
          }).catch(e => {
            this.isLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    scrollDs : function (event) {
      // 页面指定了DTD，即指定了DOCTYPE时，使用document.documentElement。
      // 页面没有DTD，即没指定DOCTYPE时，使用document.body。
      let top = event.target.scrollTop;
      if(top >= 40){
        this.cardMarginTop = {
          marginTop: "-40px",
          transition: "all 400ms"
      };
    } else {
      this.cardMarginTop = {
        transition: "all 400ms"
      };
    }
  },
  removeDomain(item) {
    let index = this.ruleForm.time_specification.indexOf(item)
    if (index !== -1) {
      this.ruleForm.time_specification.splice(index, 1)
    }
  },
  handleClose(){
    this.dialogUseStore = false;
    this.UstoreStore(this.couBatch);
    this.useStore();
  },
  choiceStore(){
    this.dialogUseStore = true;
    if(this.$route.query.couBatch !== undefined){
      this.UstoreStore(this.couBatch);
    }
  },
    useStore(){
      api.getUseStore().then(res =>{
      this.quantity = res.data.data.result.records.length;
      this.addData = res.data.data.result.records
    })
    
  },
  async UstoreStore(couBatch){
    await api.getUstoreStore(couBatch).then(res =>{
      this.checkList = res.data.data.result
    })
    this.toggleSelection();
  },
  toggleSelection() {
    this.$nextTick(() => {
      let soeId = [];
      this.checkList.forEach((row1) => {
        for (let i = 0; i < this.addData.length; i++) {
          if (row1.soeId === this.addData[i].soeId) {
            this.$refs.multipleTables.toggleRowSelection(
                    this.addData[i],
                    true
            );
          }
        }
        soeId.push(row1.soeId);
      });
      this.soeId = soeId.join(",");
    });
   
  },
  selectItem(list, row) {
      let selected = list.length && list.indexOf(row) !== -1;
      if (list.length === 0) {
        const ids = this.addData.map((i) => {
          return i.soeId;
        });
        for (var i = 0; i < this.checkList.length; i++) {
          if (ids.indexOf(this.checkList[i].soeId) > -1) {
            this.checkList.splice(i, 1);
            i--;
          }
        }
      }
      if(!selected) {//取消选中时，把checkList里的那条也删掉
        let index = this.checkList.findIndex((item) => item.soeId === row.soeId);
        if (index > -1) {
          this.checkList.splice(index, 1)
        }
      }
      const arr = [...list, ...this.checkList];
      this.checkList = this.unique(arr, 'soeId');
      let soeId = [];
      this.checkList.forEach((res) => {
        soeId.push(res.soeId);
      });
      this.soeId = soeId.join(",");
    },
    selectAll(selection) {
      if (selection.length === 0) {
        const ids = this.addData.map((i) => {
          return i.soeId;
        });
        for (var i = 0; i < this.checkList.length; i++) {
          if (ids.indexOf(this.checkList[i].soeId) > -1) {
            this.checkList.splice(i, 1);
            i--;
          }
        }
      }
      const arr = [...selection, ...this.checkList];
      this.checkList = this.unique(arr, 'soeId');
      let soeId = [];
      this.checkList.forEach((res) => {
        soeId.push(res.soeId);
      });
      this.soeId = soeId.join(",");
    },
    unique(arr,key) {//根据某个属性去重
      let map = new Map();
      return arr.filter(item => !map.has(item[key]) && map.set(item[key],1));
    },
    // selectable(row, rowIndex) {
    //   return !this.checkedAll;
    // },
    storeSubmit(){
      this.dialogUseStore = false;
    },
    addDomain() {
      if(this.ruleForm.time_specification.length === 6){
        this.$message.warning('最多添加6个次卡规格!');
        return;
      }
      this.ruleForm.time_specification.push({
        value: '',
        key: Date.now()
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  .pic {
    margin-top: 5px;
    width: 378px;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .things {
    margin-top: 5px;
    width: 95%;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .center_contanier{
    width: 93%;
    height: 100px;
    border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    align-items: center;
  }
  .others {
    margin-top: 25px;
    /*margin-left: -50px;*/
    /*width: 320px;*/
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .card_infos {
      height: 100%;
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // border-bottom: 1px solid gray;
      color: #222020;
    }
  }
}
.bg-purple-light {
  background: #e5e9f2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 15px;
}
.grid-content {
  border-radius: 4px;
  min-height: 68vh;
  height: 100%;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.member_card .logo_area {
  width: 305px;
  height: 88%;
  margin: 0;
  padding: 18px 20px 12px 20px;
  text-align: left;
  line-height: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: 1px 1px 3px #666;
}
.logo_area {
  margin-bottom: -8px;
  line-height: 42px;
  color: #FFF;
  color: rgba(255,255,255,0.9);
  height: 35vh;
  width: 260px;
  margin-top: 25px !important;
  margin-left: 16px !important;
  background-color: #FFF;
  border-radius: 10px;
  position: relative;
}
.titles{
  display: block;
  color: gray;
  font-size: 16px;
  /*width: 150px;*/
  text-align: center;
  margin-top: 20px;
  /*margin-left: 25px;*/
}
.card_title{
  display: block;
  color: #000000;
  font-size: 25px;
  /*width: 150px;*/
  text-align: center;
  /*margin-left: 25px;*/
}
.card_lit_title{
  display: block;
  color: #000000;
  font-size: 14px;
  /*width: 150px;*/
  text-align: center;
  /*margin-left: 25px;*/
  margin-top: 0px;
}
.use_btn{
  /*width: 200px;*/
  text-align: center;
  margin-top: 25px;
}
.use_time{
  text-align: center;
  display: block;
  margin-top: 30px;
  /*margin-left: -5px;*/
  font-size: 14px;
  color: #222020;
}
.member_card {
  position: absolute;
  width: 38px;
  height: 38px;
  /*top: 15px;*/
  left: 0px;
}
.vip_logo {
  display: block;
  width: 38px;
  height: 38px;
  padding-top: 0;
  margin-right: 7px;
  border-radius: 22px;
  -moz-border-radius: 22px;
  -webkit-border-radius: 22px;
  border: 1px solid #d3d3d3;
  position: absolute;
  top: -20px;
  right: 150px;
}
.vip_logo {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .05em;
  line-height: 60px;
  text-transform: uppercase;
}
.vip_logo img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -moz-border-radius: inherit;
  -webkit-border-radius: inherit;
}
.table-bordered {
  border: 1px solid #edf0f5;
  border-spacing: 0;
  border-collapse: collapse;
}
.createTimeInputWidth {
  /*width: 600px;*/
}
</style>
<style>
/*当upLoadHide为true时，启用如下样式，即上传框的样式，若为false则不启用该样式*/
.vip-form .upLoadHide .el-upload {
  display: none;
}
.select-color-radio .el-radio__inner {
  width: 30px;
  height: 30px;
}
.select-color-radio .color010 .el-radio__inner {
  background-color: #63b359;
}
.select-color-radio .color020 .el-radio__inner {
  background-color: #2c9f67;
}
.select-color-radio .color030 .el-radio__inner {
  background-color: #509fc9;
}
.select-color-radio .color040 .el-radio__inner {
  background-color: #5885cf;
}
.select-color-radio .color050 .el-radio__inner {
  background-color: #9062c0;
}
.select-color-radio .color060 .el-radio__inner {
  background-color: #d09a45;
}
.select-color-radio .color070 .el-radio__inner {
  background-color: #e4b138;
}
.select-color-radio .color080 .el-radio__inner {
  background-color: #ee903c;
}
.select-color-radio .color081 .el-radio__inner {
  background-color: #f08500;
}
.select-color-radio .color082 .el-radio__inner {
  background-color: #a9d92d;
}
.select-color-radio .color090 .el-radio__inner {
  background-color: #dd6549;
}
.select-color-radio .color100 .el-radio__inner {
  background-color: #cc463d;
}
.select-color-radio .color101 .el-radio__inner {
  background-color: #cf3e36;
}
.select-color-radio .color102 .el-radio__inner {
  background-color: #5e6671;
}
.select-color-radio .el-radio {
  margin-right: 0;
}
.select-color-radio .el-radio,
.el-radio__inner,
.el-radio__input {
  position: inherit; /*去掉选中时中间的白点*/
}
.select-color-radio .is-checked .el-radio__inner {
  background-image: url("../../assets/colorSelectActive.png");
  background-repeat: no-repeat;
  background-size: 94% 94%;
  background-position: center center;
}
.select-color-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: #ffffff;
}
</style>
