import { postRequest, getRequest, deleteRequest } from '../utils/request'

export default {
    list(params) {//列表
        const { params: para } = params
        return getRequest('/sy/merchant/list', para)
    },
    changeMerchantInfo(status, params) {
        return postRequest(`/sy/merchant/${status}`, params)
    },
    getInfoById(id) {//根据id查询详情
        return getRequest(`/sy/merchant/info/${id}`)
    },
    deleteMerchantById(id) {
        return deleteRequest(`/sy/merchant/delete/${id}`)
    },
    getAllArea() {
        return getRequest('/sy/merchant/getAllArea')
    },
    getMerchatns(){
        return getRequest(`/sy/merchant/getmerchants`)
    },
    getmerchantsByAuth(){
        return getRequest(`/sy/merchant/getmerchantsByAuth`)
    }
}
