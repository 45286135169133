import { postRequest, getRequest, deleteRequest } from '../utils/request'


export default {
    //获的代金券列表
    list(params) {
        const {params:para}=params
        return getRequest('/api/giftCard/list',para)
    },
    lqmxList(params) {
        const {params:para}=params
        return getRequest('/api/giftCard/lqmxList',para)
    },
    hxmxList(params) {
        const {params:para}=params
        return getRequest('/api/giftCard/hxmxList',para)
    },
    changeGiftInfo(status, params,typeStore,soeId){
        return postRequest(`/api/giftCard/${status}?typeStore=${typeStore}&soeId=${soeId}`, params)
    },
    echoByCouId(couId){
        return getRequest(`/api/giftCard/echoByCouId?couId=${couId}`)
    },
    getUpdateByGiftId(giftId, new_gift_use_type){
        return postRequest(`/api/giftCard/getUpdateByGiftId?giftId=${giftId}&giftType=${new_gift_use_type}`)
    },
    getUseStore(){
        return getRequest(`/sy/store/getUseStore`)
    },
    getUstoreStore(couBatch){
        return getRequest(`/sy/store/getUstoreStore?couBatch=${couBatch}`)
    }
}
